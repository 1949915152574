import React from "react";
import { navigate } from "gatsby";
import { Flex, Box } from "@rebass/grid";
import CI from "@trackcode/ci";
import { StandardButton } from "@trackcode/ui";
import Layout from "../components/Layout";
import AppBar from "../components/AppBar";

const NotFoundPage = () => (
  <Layout>
    <Flex
      flexDirection="column"
      flex={1}
      css={{ background: CI.color.grayLight }}
    >
      <AppBar />
      <Flex flex={1} flexDirection="column" alignItems="center">
        <Box css={{ marginTop: `${CI.space.lg}px`, textAlign: "center" }}>
          <h1>Seite nicht gefunden.</h1>
          <StandardButton appearance="primary" onClick={() => navigate("/")}>
            Zur Startseite
          </StandardButton>
        </Box>
      </Flex>
    </Flex>
  </Layout>
);

export default NotFoundPage;
